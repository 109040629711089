import { Account } from '@Types/account/Account';
import { Cart } from '@Types/cart/Cart';
import { Order } from '@Types/cart/Order';
import { Wishlist } from '@Types/wishlist/Wishlist';
import { UIProduct } from 'components/commercetools-ui/aafes/pdp';
import {
  CART_EVENT,
  PAGEVIEW_EVENT,
  PRODUCT_DETAIL_VIEW_EVENT,
  REFERRER_EVENT,
  SCREENSIZE_EVENT,
  CART_PRODUCT_DETAIL_VIEW_EVENT,
  PURCHASE_EVENT,
} from 'helpers/constants/aafes';

export interface MonetateData {
  page?: string;
  cart?: Cart;
  product?: UIProduct;
  singleEvent?: string;
  account?: Account;
  wishlist?: Wishlist;
  orderHistory?: any;
}

declare global {
  interface Window {
    monetateQ: any;
  }
}
let pageType = '';
let product;

export const getRecommendations = async (monetateConfig, data) => {
  let requestBody: any = {
    channel: monetateConfig.monetateChannel,
    events: [
      {
        eventType: 'monetate:decision:DecisionRequest',
        requestId: 'req-' + new Date().getTime(),
        includeReporting: true,
      },
      {
        eventType: 'monetate:context:PageView',
        url: window.location.href,
        pageType: pageType,
      },
    ],
  };
  if (pageType === 'pdp') {
    requestBody.events.push({
      eventType: 'monetate:context:ProductDetailView',
      products: [
        {
          productId: product?.productId,
        },
      ],
    });
  }

  const monetateId = getMonetateId();
  if (null !== monetateId && monetateId) {
    requestBody.monetateId = monetateId;
  } else {
    return;
  }

  if (pageType) {
    const response = await fetch(monetateConfig.monetateApiUrl, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });
    const json = await response.json();

    if ((monetateId === null || !monetateId) && json.meta?.monetateId) {
      setMonetateId(json.meta?.monetateId);
    }

    const recommendationData = json?.data?.responses
      ?.flatMap((response) => response?.actions)
      ?.find((action) => action?.component === data?.recommendationComponent);

    return recommendationData?.items ?? [];
  } else {
    return false;
  }
};

export const sendToMonetate = async (data: MonetateData, monetateConfig) => {
  let requestBody: any = {
    channel: monetateConfig.monetateChannel,
    events: [],
  };
  let eventsToSend;
  const monetateId = getMonetateId();
  if (null !== monetateId && monetateId) {
    requestBody.monetateId = monetateId;
  }
  if (data.singleEvent === PRODUCT_DETAIL_VIEW_EVENT) {
    eventsToSend = [PRODUCT_DETAIL_VIEW_EVENT];
  } else if (data.page === 'homepageAfterLogin') {
    eventsToSend = [PAGEVIEW_EVENT, SCREENSIZE_EVENT, CART_EVENT];
  } else if (data.page === 'homePageWithoutLogin') {
    eventsToSend = [SCREENSIZE_EVENT, PAGEVIEW_EVENT];
  } else if (data.page === 'pdp') {
    eventsToSend = [PAGEVIEW_EVENT, PRODUCT_DETAIL_VIEW_EVENT, SCREENSIZE_EVENT, CART_EVENT, REFERRER_EVENT];
  } else if (data.page === 'cart') {
    eventsToSend = [PAGEVIEW_EVENT, CART_PRODUCT_DETAIL_VIEW_EVENT, SCREENSIZE_EVENT, CART_EVENT, REFERRER_EVENT];
  } else if (data.page === 'purchase') {
    eventsToSend = [PURCHASE_EVENT];
  } else {
    eventsToSend = [PAGEVIEW_EVENT, SCREENSIZE_EVENT, CART_EVENT, REFERRER_EVENT];
  }
  requestBody = await addEvents(eventsToSend, requestBody, data, monetateConfig);
  try {
    const response = await fetch(monetateConfig.monetateApiUrl, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    });
    const responseData = await response.json();
    if ((null === monetateId || !monetateId) && responseData.meta?.monetateId) {
      setMonetateId(responseData.meta?.monetateId);
    }
    window?.monetateQ?.push(['trackData']);
    return responseData;
  } catch (error) {
    return { error: true };
  }
};

const addEvents = async (eventList, requestBody, data, monetateConfig) => {
  const sessionNavHistory = window?.sessionNavHistory;
  const referrerUrl = sessionNavHistory[sessionNavHistory.length - 2];
  let updatedRequestBody = { ...requestBody };
  for (const event of eventList) {
    if (event === PAGEVIEW_EVENT) {
      const pageViewEvent = await getPageViewEvent(data);
      if (pageViewEvent) {
        updatedRequestBody.events.push(pageViewEvent);
      }
    } else if (event === CART_EVENT) {
      updatedRequestBody.events.push(getCartEvent(data));
    } else if (event === PRODUCT_DETAIL_VIEW_EVENT) {
      updatedRequestBody.events.push(getProductDetailViewEvent(data));
    } else if (event === REFERRER_EVENT) {
      updatedRequestBody.events.push(getReferrerEvent(referrerUrl));
    } else if (event === SCREENSIZE_EVENT) {
      updatedRequestBody.events.push(getScreenSizeEvent());
    } else if (event === CART_PRODUCT_DETAIL_VIEW_EVENT) {
      updatedRequestBody.events.push(getCartProductDetailViewEvent(data));
    } else if (event === PURCHASE_EVENT) {
      updatedRequestBody.events.push(getPurchaseEvent(data));
    }
  }
  return updatedRequestBody;
};

const getPageViewEvent = async (data) => {
  if (
    window.location.pathname.indexOf('account') > -1 &&
    window.location.hash.indexOf('communicationPreference') > -1
  ) {
    pageType = 'communication_preferences';
  } else if (window.location.pathname.indexOf('account') > -1 && window.location.hash.indexOf('profile') > -1) {
    pageType = 'profile';
  } else if (window.location.pathname.indexOf('account') > -1 && window.location.hash.indexOf('wishlist') > -1) {
    pageType = 'wish_list';
  } else if (window.location.pathname.indexOf('account') > -1 && window.location.hash.indexOf('addresses') > -1) {
    pageType = 'address_book';
  } else if (window.location.pathname.indexOf('account') > -1 && window.location.hash.indexOf('wallet') > -1) {
    pageType = 'credit_cards';
  } else if (window.location.pathname.indexOf('account') > -1 && window.location.hash.indexOf('subscription') > -1) {
    pageType = 'subscriptions';
  } else if (window.location.pathname.indexOf('account') > -1 && window.location.hash.indexOf('orders') > -1) {
    pageType = 'order_history';
  } else if (window.location.pathname.indexOf('marketplace') > -1) {
    pageType = 'marketplace';
  } else if (window.location.pathname.indexOf('account') > -1) {
    pageType = 'myaccount';
  } else if (RegExp(/\/([^\/]+)\/(DSCO\d{10}|\d{7})/).exec(window?.location?.pathname)) {
    pageType = 'pdp';
    await pushProductID(data);
    await pushDetailPagesCategories(data);
  } else if (window.location.pathname.indexOf('cart') > -1) {
    pageType = 'cart';
  } else if (window.location.pathname == '/') {
    pageType = 'homepage';
  } else if (window.location.pathname.indexOf('checkout') > -1) {
    pageType = 'checkout';
  } else if (window.location.pathname.indexOf('business-delivery') > -1) {
    pageType = 'business_delivery';
  } else if (window.location.pathname.indexOf('savings-center') > -1) {
    pageType = 'deals-page';
  } else if (data.page === 'marketplace_pdp') {
    pageType = 'marketplace_pdp';
  } else if (data.page === 'compare') {
    pageType = 'compare';
  } else if (data.page === 'search') {
    pageType = 'search';
    await searchQuery(data);
    await pushProductIDs(data);
  } else if (data.page === 'minicart') {
    pageType = 'pdp';
  } else if (data?.page === 'plp' || data?.page === 'clp') {
    pushProductIDs(data);
    pushLandingPagesCategories(data);
    if (data?.product?.selectedFilters?.length === 1) {
      pageType = 'plp_level1';
    } else if (data?.product?.selectedFilters?.length === 2) {
      pageType = 'plp_level2';
    } else if (data?.product?.selectedFilters?.length === 3) {
      pageType = 'plp_level3';
    } else if (data?.product?.selectedFilters?.length >= 4) {
      pageType = 'plp_level4';
    }
  }
  if (pageType) {
    pushCartItems(data);
    window.monetateQ = window.monetateQ || [];
    window.monetateQ.push(['setPageType', pageType]);
    return {
      eventType: 'monetate:context:PageView',
      url: window.location.href,
      pageType: pageType,
    };
  } else {
    return false;
  }
};

const getScreenSizeEvent = () => {
  return {
    eventType: 'monetate:context:ScreenSize',
    height: screen?.height,
    width: screen?.width,
  };
};

const getCartEvent = (data) => {
  const cart = data?.cart;
  let cartEventData = {
    eventType: 'monetate:context:Cart',
    cartLines: [],
  };
  if (cart?.lineItems?.length > 0) {
    for (const lineItem of cart.lineItems) {
      cartEventData.cartLines.push({
        value: lineItem?.lineItemTotalPrice?.centAmount
          ? (lineItem?.lineItemTotalPrice?.centAmount / 100).toFixed(2)
          : '0.00',
        sku: lineItem?.variant?.sku,
        pid: lineItem?.productKey,
        quantity: lineItem?.count,
        currency: lineItem?.lineItemTotalPrice?.currencyCode,
      });
    }
  }
  return cartEventData;
};
const searchQuery = (data) => {
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push([
    'addSearch',
    {
      searchTerm: data ? data?.product?.query : [],
    },
  ]);
  window?.monetateQ?.push(['trackData']);
};

const pushCartItems = (data) => {
  const cart = data?.cart;
  const cartLines = [];
  if (cart?.lineItems?.length > 0) {
    for (const lineItem of cart.lineItems) {
      cartLines.push({
        productId: lineItem?.productKey,
        quantity: lineItem?.count,
        unitPrice: lineItem?.lineItemTotalPrice?.centAmount
          ? (lineItem?.lineItemTotalPrice?.centAmount / 100).toFixed(2)
          : '0.00',
        sku: lineItem?.variant?.sku,
        currency: lineItem?.lineItemTotalPrice?.currencyCode,
      });
    }
  }
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(['addCartRows', cartLines]);
  window?.monetateQ?.push(['trackData']);
};
const pushProductID = async (data) => {
  const productData = (data?.product?.variants ?? []).map((variant) => ({
    productId: data?.product?.productId,
    sku: variant?.sku,
  }));

  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(['addProductDetails', productData]);
  window?.monetateQ?.push(['trackData']);
};

const pushProductIDs = async (data) => {
  const productIds = [];

  (data?.product?.records ?? []).forEach((product) => {
    productIds.push({
      productId: product?.repositoryId,
    });
  });

  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(['addProducts', productIds]);
  window?.monetateQ?.push(['trackData']);
};
const pushLandingPagesCategories = async (data) => {
  const filtersList = (data?.product?.selectedFilters ?? []).flatMap((filter) => filter?.refinements?.[0]?.name || []);
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(['addCategories', filtersList]);
  window?.monetateQ?.push(['trackData']);
};
const pushDetailPagesCategories = async (data) => {
  const filtersList = data?.product && [
    ...(data?.product?.categories[0]?.parent ?? []).slice(1).map((filter) => filter?.name || []),
    data?.product?.categories?.[0]?.name,
  ];

  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(['addCategories', filtersList]);
  window?.monetateQ?.push(['trackData']);
};
const getReferrerEvent = (referrerUrl) => {
  return {
    eventType: 'monetate:context:Referrer',
    referrer: referrerUrl,
  };
};
const getMonetateId = () => {
  return localStorage.getItem('monetateId');
};

const setMonetateId = (id) => {
  localStorage.setItem('monetateId', id);
};

const getProductDetailViewEvent = (data) => {
  let pageType;
  if (window.location.pathname == '/') {
    pageType = 'homepage';
  }
  product = data?.product;
  let productDetailViewEventData = {
    eventType: 'monetate:context:ProductDetailView',
    products: [],
    pageType: pageType,
  };
  if (product) {
    for (const variants of product?.variants) {
      productDetailViewEventData.products.push({
        productId: product?.productId,
        sku: variants?.sku,
      });
    }
  }
  window.monetateQ = window.monetateQ || [];
  window.monetateQ.push(['addProductDetails', productDetailViewEventData.products]);
  window?.monetateQ?.push(['trackData']);
  pushCartItems(data);
  return productDetailViewEventData;
};

const getCartProductDetailViewEvent = (data) => {
  const cart = data?.cart;
  let productDetailViewEventData = {
    eventType: 'monetate:context:ProductDetailView',
    products: [],
  };
  if (cart?.lineItems?.length > 0) {
    for (const lineItem of cart?.lineItems) {
      productDetailViewEventData?.products.push({
        productId: lineItem?.productId,
        sku: lineItem?.variant?.sku,
      });
    }
  }
  return productDetailViewEventData;
};

const getPurchaseEvent = (data) => {
  const cart = data?.cart;
  let purchaseEventData = {
    eventType: 'monetate:context:Purchase',
    account: cart.cid ? '' + cart.cid : undefined,
    domain: window.location.hostname,
    instance: 'd',
    purchaseId: cart?.orderId,
    purchaseLines: [],
  };
  if (cart?.lineItems?.length > 0) {
    for (const lineItem of cart?.lineItems) {
      purchaseEventData.purchaseLines.push({
        value: lineItem?.lineItemTotalPrice?.centAmount
          ? (lineItem?.lineItemTotalPrice?.centAmount / 100).toFixed(2)
          : '0.00',
        sku: lineItem?.variant?.sku,
        pid: lineItem?.productKey,
        quantity: lineItem?.count,
        currency: lineItem?.lineItemTotalPrice?.currencyCode,
      });
    }
  }
  return purchaseEventData;
};
